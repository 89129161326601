import React from 'react'
import styles from './Price.module.scss'
import { motion } from "framer-motion"

const titleAnimation = {
    hidden: {
        y: 100,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .3 }
    }
}

const cardAnimation = {
    hidden: {
        y: 100,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .4}
    } 
}


const Price = () => {

    return (
        <motion.section 
        className={styles.main}
        id='gruppy' 
        initial='hidden'
        whileInView='visible'
        viewport={{amount: 0.05, once: true}}
        >
            <div className={styles.container}>
                <motion.h2 className={styles.title} variants={titleAnimation}>ПОПУЛЯРНЫЕ УСЛУГИ КЛИНИКИ MR.DENT</motion.h2>
                <motion.ul className={styles.list}>
                    <motion.li 
                        className={styles.cell}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.01, once: true}}
                        >
                        <h3 className={styles.cell_subtitle}>Хирургия</h3>
                        <p className={styles.cell_text}>Прием (осмотр, консультация) врача-стоматолога-хирурга</p>
                        <p className={styles.cell_price}>500₽</p>
                        <p className={styles.cell_text}>Удаление постоянного зуба. Простое</p>
                        <p className={styles.cell_price}>2 000₽</p>
                        <p className={styles.cell_text}>Удаление постоянного зуба. Сложное, с разъединением корней</p>
                        <p className={styles.cell_price}>3 000₽</p>
                        <p className={styles.cell_text}>Операция по удалению непрорезавшегося зуба мудрости( 8-й зуб), дистопированного или сверхкомплектного зуба</p>
                        <p className={styles.cell_price}>от 3 500₽</p>
                        <a className={styles.button} target='_blank' rel='noopener noreferrer' href='https://booking.medflex.ru/?user=c8ede4e9fb4aaad0f59bbbd50c161354&isRoundWidget=true&filial=12509&type=doctors' aria-label='записаться на прием'>записаться на прием</a>
                    </motion.li>
                    <motion.li 
                        className={styles.cell_one}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.01, once: true}}
                        >
                        <h3 className={styles.cell_subtitle_one}>Терапия</h3>
                        <p className={styles.cell_text}>Прием (осмотр, консультация)  врача-стоматолога-терапевта</p>
                        <p className={styles.cell_price}>500₽</p>
                        <p className={styles.cell_text}>Восстановление зуба пломбой с использованием материалов из фотополимеров</p>
                        <p className={styles.cell_price}>от 3 500₽</p>
                        <p className={styles.cell_text}>Лечение корневых каналов</p>
                        <p className={styles.cell_price}>от 3 000₽</p>
                        <a className={styles.button} target='_blank' rel='noopener noreferrer' href='https://booking.medflex.ru/?user=c8ede4e9fb4aaad0f59bbbd50c161354&isRoundWidget=true&filial=12509&type=doctors' aria-label='записаться на прием'>записаться на прием</a>
                    </motion.li>
                    <motion.li 
                        className={styles.cell_two}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.01, once: true}}
                        >
                        <h3 className={styles.cell_subtitle_two}>Ортопедия</h3>
                        <p className={styles.cell_text_two}>Прием (осмотр, консультация) врача-стоматолога-ортопеда</p>
                        <p className={styles.cell_price_two}>500₽</p>
                        <p className={styles.cell_text_two}>Восстановление зуба металлокерамической коронкой</p>
                        <p className={styles.cell_price_two}>8 000₽</p>
                        <p className={styles.cell_text_two}>Восстановление зуба коронкой из диоксида циркония</p>
                        <p className={styles.cell_price_two}>от 9 000₽</p>
                        <p className={styles.cell_text_two}>Протезирование зубов с использованием имплантата</p>
                        <p className={styles.cell_price_two}>от 18 000₽</p>
                        <a className={styles.button} target='_blank' rel='noopener noreferrer' href='https://booking.medflex.ru/?user=c8ede4e9fb4aaad0f59bbbd50c161354&isRoundWidget=true&filial=12509&type=doctors' aria-label='записаться на прием'>записаться на прием</a>
                    </motion.li>
                    <motion.li 
                        className={styles.cell_one}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.01, once: true}}
                        >
                        <h3 className={styles.cell_subtitle_one}>Имплантология</h3>
                        <p className={styles.cell_text}>Внутрикостная дентальная имплантация. Операция установки импланта "Super Line Dentium"</p>
                        <p className={styles.cell_price}>25 000₽</p>
                        <p className={styles.cell_text}>Внутрикостная дентальная имплантация. Установка формирователя десны "Dentium"</p>
                        <p className={styles.cell_price}>4 000₽</p>
                        <a className={styles.button} target='_blank' rel='noopener noreferrer' href='https://booking.medflex.ru/?user=c8ede4e9fb4aaad0f59bbbd50c161354&isRoundWidget=true&filial=12509&type=doctors' aria-label='записаться на прием'>записаться на прием</a>
                    </motion.li>
                </motion.ul>
            </div>   
    </motion.section>
    )
}

export default Price

