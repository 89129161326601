import React from 'react'
import header from './HeaderContact.module.scss'

const HeaderContact = ({isOpen}) => {

    return (
        <div className={header.contacts}>
            <div className={header.call}>
                <div className={header.call_pulse}></div>
                <p className={header.call_text}>Звоните, ПН-ПТ 10.00-19.00</p>
            </div>
            <div className={header.box_phone}>
                <a className={header.phone}  href="tel:+79100225050" target='_blank' rel='noopener noreferrer'>+7 910 <span>022-50-50</span></a>
            </div>
            <p className={header.text} onClick={isOpen}>Бесплатная консультация</p>
        </div>
    );
};

export default HeaderContact