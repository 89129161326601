
import adv_1 from '../image/Advantages/img_1.jpg'
import adv_2 from '../image/Advantages/img_2.jpg'
import adv_3 from '../image/Advantages/img_3.jpg'
import adv_4 from '../image/Advantages/img_4.webp'
import adv_5 from '../image/Advantages/img_5.jpg'
import adv_6 from '../image/Advantages/img_6.jpg'

import lic_1 from '../image/Map/license.jpg'
import lic_2 from '../image/Map/license_1.jpg'

export const arrAdvantages = [{img: adv_1, title: 'ЛЕЧЕНИЕ БЕЗ БОЛИ', text: "Все беседы остаются строго в рамках кабинета"}, {img: adv_2, title: 'ПРИЕМ ВЕДЕТ К.М.Н.', text: "Работаю с чувствами не используя понятия “хорошо-плохо”"}, {img: adv_3, title: 'СОВРЕМЕННОЕ ОБОРУДОВАНИЕ', text: "Чтобы быть максимально эффективной в работе с клиентами"}, {img: adv_4, title: 'СКИДКА 10% ДЛЯ ПЕНСИОНЕРОВ', text: "Прием в вечернее время в рабочие дни и онлайн в выходные"}, {img: adv_5, title: 'МНОГОЛЕТНИЙ ОПЫТ', text: "Позволяет более глубоко и продуктивно проработать Вашу проблему"}, {img: adv_6, title: 'СОВРЕМЕННЫЕ МЕТОДЫ', text: "Позволяет проводить лечение наиболее эффективным для Вас способом"},]

export const arrLicense = [{image: lic_1, title: 'лицензия'}, {image: lic_2, title: 'лицензия'}, ];