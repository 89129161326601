import React from 'react'
import styles from './Advantages.module.scss'
import { motion } from "framer-motion"
import { v4 as uuidv4 } from 'uuid'
import { arrAdvantages } from '../../utils/data'

const titleAnimation = {
    hidden: {
        y: 100,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .3 }
    }
}

const cardAnimation = {
    hidden: {
        y: 100,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .4}
    } 
}


const Advantages = ({isOpen}) => {

    return (
        <motion.section 
        className={styles.main}
        id='gruppy' 
        initial='hidden'
        whileInView='visible'
        viewport={{amount: 0.05, once: true}}
        >
        <div className={styles.container}>
        <motion.h2 className={styles.title} variants={titleAnimation}>ПОЧЕМУ ПАЦИЕНТЫ ВЫБИРАЮТ MR.DENT</motion.h2>
        <motion.ul className={styles.list}>
        {arrAdvantages.map((item, index) => {
                        const keyUid = uuidv4()
                                if(index  !== 1 && 0){
                                    return(
                                    <motion.li 
                                        className={styles.cell}
                                        variants={cardAnimation}
                                        initial='hidden'
                                        whileInView='visible'
                                        viewport={{amount: 0.01, once: true}}
                                        key={keyUid}
                                        >
                                        <div className={styles.cell_image}>
                                            <img className={styles.image} src={item.img} alt='человек'/>
                                            <div className={styles.cell_overlay}></div>
                                        </div>
                                        <div className={styles.cell_info}>
                                            <h3 className={styles.cell_subtitle}>{item.title}</h3>
                                            <a className={styles.button} target='_blank' rel='noopener noreferrer' href='https://booking.medflex.ru/?user=c8ede4e9fb4aaad0f59bbbd50c161354&isRoundWidget=true&filial=12509&type=doctors' aria-label='записаться на прием'>ЗАПИСАТЬСЯ
                                            <svg  viewBox="0 0 19 19"  xmlns="http://www.w3.org/2000/svg">
                                                <path d="M17.1646 8.3086L0.884766 8.30859L0.884766 10.0781L17.1646 10.0781L17.1646 8.3086Z" ></path>
                                                <path d="M14.0596 13.5751L12.8083 12.3238L15.9115 9.22063L12.8083 6.11748L14.0596 4.86621L18.414 9.22063L14.0596 13.5751Z" ></path>
                                            </svg>
                                            </a>
                                        </div>
                                    </motion.li>
                                    )
                                } else {
                                    return (
                                    <motion.li 
                                        className={styles.cell}
                                        variants={cardAnimation}
                                        initial='hidden'
                                        whileInView='visible'
                                        viewport={{amount: 0.01, once: true}}
                                        key={keyUid}
                                        >
                                        <div className={styles.cell_image}>
                                            <img className={styles.image} src={item.img} alt='человек'/>
                                            <div className={styles.cell_overlay}></div>
                                        </div>
                                        <div className={styles.cell_info}>
                                            <h3 className={styles.cell_subtitle}>{item.title}</h3>
                                            <a className={styles.button} target='_blank' rel='noopener noreferrer' href='https://booking.medflex.ru/?user=c8ede4e9fb4aaad0f59bbbd50c161354&isRoundWidget=true&filial=12509&type=doctors' aria-label='записаться на прием'>ЗАПИСАТЬСЯ
                                            <svg  viewBox="0 0 19 19"  xmlns="http://www.w3.org/2000/svg">
                                                <path d="M17.1646 8.3086L0.884766 8.30859L0.884766 10.0781L17.1646 10.0781L17.1646 8.3086Z" ></path>
                                                <path d="M14.0596 13.5751L12.8083 12.3238L15.9115 9.22063L12.8083 6.11748L14.0596 4.86621L18.414 9.22063L14.0596 13.5751Z" ></path>
                                            </svg>
                                            </a>
                                        </div>
                                    </motion.li>
                                    )}
                    })} 
        </motion.ul>
        </div>   
    </motion.section>
    )
}

export default Advantages