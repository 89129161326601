import React from 'react'
import styles from './Main.module.scss'
import InitialPage from '../../components/initialPage/InitialPage'
import Price from '../../components/Price/Price'
import Advantages from '../../components/Advantages/Advantages'
import Feedback from '../../components/Feedback/Feedback'
import Callback from '../../components/Callback/Callback'
import Map from '../../components/Map/Map'

const Main = ({isOpen, popupOpen, isLicenseOpen}) => {

    return (
        <section className={styles.main}>
            <InitialPage
                isOpen={isOpen}
            />
            <Advantages
                isOpen={isOpen}
            />
            <Price/>
            <Feedback/>
            <Callback
                popupOpen={popupOpen}
            />
            <Map
                isLicenseOpen={isLicenseOpen}
            />
        </section>
    )
}

export default Main