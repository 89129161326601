import React from 'react'
import styles from './InitialPage.module.scss'
import wp from '../../image/InitialPage/touch.svg'


const InitialPage = () => {
    return (
        <section className={styles.main}>
            <div className={styles.overlay}>
                <div className={styles.container}>
                    <h1 className={styles.title}>СТОМАТОЛОГИЧЕСКАЯ КЛИНИКА МИСТЕРДЕНТ</h1>
                    <p className={styles.text}>Ваша улыбка - наша забота!</p>
                    <div className={styles.box_button}>
                        <a className={styles.button} target='_blank' rel='noopener noreferrer' href='https://booking.medflex.ru/?user=c8ede4e9fb4aaad0f59bbbd50c161354&isRoundWidget=true&filial=12509&type=doctors' aria-label='записаться на прием'>записаться на прием <span className={styles.glare}></span> <img src={wp} alt="Whatsapp"/></a>
                    </div>
                </div>
            </div> 
        </section>
    )
}

export default InitialPage