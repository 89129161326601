import React, { useCallback, useState, useEffect, useRef } from 'react'
import emailjs from 'emailjs-com'
import { createPortal } from "react-dom"
import styles from './ModalForm.module.scss'
import {ModalOverlay} from '../ModalOverlay/ModalOverlay'
import logo from '../../image/Header/logo.svg'
import { FormValidation } from '../../hooks/FormValidation'
import InputMask from "react-input-mask"
import disactive from '../../image/Callback/check.svg'

const ModalForm = ({onClose, popupOpen}) => {

    const modalRoot = document.getElementById("modals");
    
    const handleForm = FormValidation()
    const [buttonDisabled, setButtonDisabled] = useState(true)
    const form = useRef()

    useEffect(() => {
        handleForm.isValid ? setButtonDisabled(false) : setButtonDisabled(true)
    }, [handleForm.isValid])

    const sendData = useCallback((evt) => {
        evt.preventDefault();
            emailjs.sendForm('service_bupcm99', 'template_i01xu72', form.current, 'ICdIfa1W_URYe5Gez')
            .then((result) => {
            }, (error) => {
                console.log(error.text);
            }); 
        onClose()
        handleForm.resetForm()
        popupOpen()
    }, [handleForm, onClose, popupOpen] )

    useEffect(() => {
        const handleEscClose = (evt) =>{
            if (evt.key ==='Escape')  onClose()
        }
        document.addEventListener('keydown', handleEscClose)
        return () => document.removeEventListener('keydown', handleEscClose)
    }, [onClose])

    return createPortal (
        <ModalOverlay onClick={onClose}>
            <div className={styles.modal} onClick={evt => evt.stopPropagation()}>
                <div className={styles.box_logo}>
                    <img className={styles.logo} src={logo} alt='логотип'/>
                    <button data-test="close-button" className={styles.close_icon} type="button" aria-label="закрыть" onClick={onClose}/>
                </div>
                <div className={styles.box}>
                    <h2 className={styles.title}>Оставьте ваши данные</h2>
                    <p className={styles.subtitle}>Мы свяжемся с вами в ближайшее время и ответим на все вопросы!</p>
                        <form className={styles.form} onSubmit={sendData} ref={form}>
                            <div className={styles.box_input}>
                                <label className={styles.label}>Введите имя
                                    <input
                                        className={`${styles.input} ${handleForm.errors.name && styles.wrong}`}
                                        type='text' 
                                        name='name'
                                        value={handleForm.values.this}
                                        onChange={handleForm.handleChange} 
                                        pattern='^[A-Za-zА-Яа-яЁё\s\-]{2,30}$'  
                                        required/>
                                </label>
                            </div>
                            <span className={styles.input_error}>{handleForm.errors.name}</span>
                            <div className={styles.box_input}>
                                <label className={styles.label}>Введите телефон      
                                    <InputMask
                                        className={`${styles.input} ${handleForm.errors.phone && styles.wrong}`}
                                        type='tel' 
                                        name='phone'
                                        mask={'+7\\(999) 999-99-99'}
                                        value={handleForm.values.this}
                                        onChange={handleForm.handleChange}  
                                        pattern='\+7\s?[\(][0-9]{3}[\)]{0,1}\s?\d{3}[-]{0,1}\d{2}[-]{0,1}\d{2}'
                                        required/>
                                </label>
                            </div>            
                            <span className={styles.input_error}>{handleForm.errors.phone}</span>
                                    <button className={`${styles.button_form} ${buttonDisabled && styles.button_disabled}`}type='submit'>жду звонка <span className={styles.glare}></span></button>
                                    <div className={styles.box_item}>
                                        <div className={styles.icon_boxs}>
                                            <img className={styles.icon} src={disactive} alt='галочка'/>
                                        </div>
                                    <div className={styles.box_text}>
                                        <p className={styles.text}>Cогласен с условиями политики конфиденциальности данных</p>
                                    </div>
                                </div>
                        </form>
                        
                </div>
            </div>
        </ModalOverlay>
        ,modalRoot
    );
};

export default ModalForm