import React from 'react'
import { motion } from "framer-motion"
import styles from './Feedback.module.scss'
import { Swiper, SwiperSlide } from 'swiper/react'
import {  Pagination, Autoplay} from "swiper"
import "./Feedback.css"
import 'swiper/scss'
import "swiper/scss/pagination"
import "swiper/scss/navigation"
import img from '../../image/Feedback/img.webp'
import Annotation from '../Annotation/Annotation'

const boxAnimation = {
    hidden: {
        y: 400,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: 1}
    } 
}


const Feedback = () => {

    return (
        <motion.section 
            className={styles.main}
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.1, once: true}}
            >
            <motion.div className={styles.box} variants={boxAnimation}>
                <h2 className={styles.title}>ОТЗЫВЫ НАШИХ ПАЦИЕНТОВ</h2>
                <Annotation/>
                    <div className={styles.slider}>
                        <Swiper
                                spaceBetween={10}
                                slidesPerView={1.3}
                                centeredSlides={true}
                                loop={true}
                                autoplay={{
                                    delay: 3000,
                                    disableOnInteraction: false,
                                }}
                                pagination={{
                                    type: 'fraction'
                                }}
                                breakpoints={{

                                    1400: {
                                        slidesPerView: 1.5,
                                        spaceBetween: 5,
                                    },

                                    768: {
                                        slidesPerView: 1.2,
                                        spaceBetween: 5,
                                    },

                                    425: {
                                        slidesPerView: 1,
                                        spaceBetween: 5,
                                    },

                                    320: {
                                        slidesPerView: 1,
                                        spaceBetween: 5,
                                    },
                                }}
                                modules={[Pagination, Autoplay]}
                                className="mySwiper"
                                >
                            <SwiperSlide>
                                <div className={styles.cell}>
                                    <div className={styles.item}>
                                        <p className={styles.subtitle}>Валерия Л.</p>
                                        <p className={styles.comment}>14.04.2024</p>
                                        <img className={styles.image} src={img} alt = 'звездочки'/>
                                        <p className={styles.comment}>Комментарий</p>
                                        <p className={styles.text}>Мы с мужем были проездом в Курске и у меня по дороге неимоверно разболелся зуб. Нашли первую клинику, которая встретилась и это оказалась МистерДент. Мы были приятно удивлены, что нас приняли здесь без записи с острой болью. Думали, что в наше время такое уже не практикуется) Оказывается, есть еще в России такие места)) Меня сразу отправили на снимок и назначили врача. Зуб вылечили быстро, безболезненно, да еще и по разумной цене. Спасибо большое за порядочность и профессионализм!</p>
                                    </div>
                                </div>
                            </SwiperSlide>        
                            <SwiperSlide>
                                <div className={styles.cell}>
                                    <div className={styles.item}>
                                        <p className={styles.subtitle}>DENIS MIHAILOV</p>
                                        <p className={styles.comment}>27.06.2024</p>
                                        <img className={styles.image} src={img} alt = 'звездочки'/>
                                        <p className={styles.comment}>Комментарий</p>
                                        <p className={styles.text}>У меня развился кариес между зубами. Мне провели лечение, поставили пломбы и порекомендовали более тщательно ухаживать за полостью рта. Теперь только хожу к стоматологу Разинькова Татьяна Владимировна просто на прием, для профилактики. Хочу выразить огромную благодарность ей за то , что не просто вылечила мне зубы, но еще и дала очень ценные рекомендации, которые позволили сохранить эмаль и избежать появления нового кариеса.</p>                              
                                    </div>
                                </div>
                            </SwiperSlide>        
                            <SwiperSlide>
                                <div className={styles.cell}>
                                    <div className={styles.item}>
                                        <p className={styles.subtitle}>Луиза Хафизова</p>
                                        <p className={styles.comment}>23.02.2024</p>
                                        <img className={styles.image} src={img} alt = 'звездочки'/>
                                        <p className={styles.comment}>Комментарий</p>
                                        <p className={styles.text}>С детства боюсь стоматологов. Но, после посещения "МистерДент"а, я поменяла отношение. Здесь работают волшебники, профессионалы своего дела. Безболезненно вылечили мои зубы. Если вы боитесь также как и я стоматологов, то вам именно в эту клинику. Можете быть уверены, здесь работают самые лучшие врачи.</p>                              
                                    </div>
                                </div>
                            </SwiperSlide>        
                            <SwiperSlide>
                                <div className={styles.cell}>
                                    <div className={styles.item}>
                                        <p className={styles.subtitle}>Алексей</p>
                                        <p className={styles.comment}>01.02.2024</p>
                                        <img className={styles.image} src={img} alt = 'звездочки'/>
                                        <p className={styles.comment}>Комментарий</p>
                                        <p className={styles.text}>Посетил клинику еще в 23 году на консультации выявлено кариес и рекомендовали чистку зубного камня. Камни почистили без боли и быстро. В феврале 24 го года пришел на пломбирование. Принял лично Юрий Владимирович. Поставил две пломбы быстро и безболезненно. В клинике все на высшем уровне. Главное это отношение к пациентам, вежливое и внимательное. Спасибо огромное !!!</p>                              
                                    </div>
                                </div>
                            </SwiperSlide>        
                            <SwiperSlide>
                                <div className={styles.cell}>
                                    <div className={styles.item}>
                                        <p className={styles.subtitle}>Анна Ледовская</p>
                                        <p className={styles.comment}>27.03.2023</p>
                                        <img className={styles.image} src={img} alt = 'звездочки'/>
                                        <p className={styles.comment}>Комментарий</p>
                                        <p className={styles.text}>Записала на приём супруга. Сам откладывал визит к стоматологу до последнего момента. Пришлось удалять зубы. Нам повезло, что попал на приём к Мищенко Юрию Владимировичу. Доктор внимательно произвёл осмотр, сделали рентген​. Удалил зубы быстро, а самое главное безболезненно. Назначил лечение. Муж остался очень доволен. Теперь рекомендует всем знакомым именно Юрия Владимировича, замечательного доктора и человека.</p>                              
                                    </div>
                                </div>
                            </SwiperSlide>           
                            <SwiperSlide>
                                <div className={styles.cell}>
                                    <div className={styles.item}>
                                        <p className={styles.subtitle}>Vlad</p>
                                        <p className={styles.comment}>18.01.2023</p>
                                        <img className={styles.image} src={img} alt = 'звездочки'/>
                                        <p className={styles.comment}>Комментарий</p>
                                        <p className={styles.text}>Я обращался к Юрию Владимировичу по поводу протезирования зуба. Врач подробно рассказал мне о вариантах протезирования и всей процедуре. Направил на КТ​ и подсказал место, где можно его сделать. После изучения КТ дал полный анализ​ не только по данной проблеме, а вообще по возможным проблемам с зубами в перспективе. Приехав в отпуск домой, решил обратиться со своей проблемой и Юрий Владимирович разу нашел для меня свободное время, несмотря на праздничные дни. Так как постоянно проживаю в другом городе, перед нами стояли определенные временные рамки, в которые нужно все было успеть и, несмотря на это, врач сделал все, чтобы в них уложиться. Большое Вам спасибо!</p>                              
                                    </div>
                                </div>
                            </SwiperSlide>        
                            <SwiperSlide>
                                <div className={styles.cell}>
                                    <div className={styles.item}>
                                        <p className={styles.subtitle}>Маргарита К.</p>
                                        <p className={styles.comment}>15.12.2022</p>
                                        <img className={styles.image} src={img} alt = 'звездочки'/>
                                        <p className={styles.comment}>Комментарий</p>
                                        <p className={styles.text}>Юрия Владимировича посещаем не в первый раз. У него лечили, удаляли, вставляли зубы не только наша семья, но и родственники, и знакомые. Все довольны результатом. В этот раз были на приёме с сыном. Удаляли восьмёрку. Доктор предупредил, что зуб сложный, рассказал как будет происходить удаление. Всё прошло хорошо.</p>                              
                                    </div>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                    <a className={styles.button} target='_blank' rel='noopener noreferrer' href='https://wa.me/+79100225050' aria-label='связаться вацап'>
                        <span>Задать свой вопрос в Whatsapp</span>
                        <svg  xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.92327 0.277344C4.59796 0.277344 0.2771 4.5982 0.2771 9.92351C0.2771 12.0559 0.967314 14.0199 2.13451 15.6192L0.905588 19.2554L4.73824 18.0602C6.23651 19.0141 8.01536 19.5697 9.92327 19.5697C15.2486 19.5697 19.5694 15.2488 19.5694 9.92351C19.5694 4.5982 15.2486 0.277344 9.92327 0.277344ZM14.3395 15.0973C12.5887 15.7988 10.5013 15.1198 8.21737 13.3858C5.93349 11.6519 4.42961 9.21085 4.3286 7.39834C4.2276 5.58582 5.70342 4.67115 6.50587 4.67676C7.30831 4.68237 8.18932 7.24683 8.20054 7.63963C8.21176 8.03805 7.21853 8.7451 7.17925 9.08179C7.13997 9.41848 8.53162 11.0795 9.12083 11.5284C9.70442 11.9717 10.9109 12.8247 11.5338 12.7461C12.151 12.6619 12.5607 11.4218 12.9535 11.3432C13.3463 11.2647 15.4899 12.3308 15.7031 12.6058C15.9164 12.8864 15.8939 14.4801 14.3395 15.0973Z"></path>
                        </svg>
                    </a>         
                </motion.div>
        </motion.section>
    )
}

export default Feedback